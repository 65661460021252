import moment from 'moment'
import jsPDF from 'jspdf'  
import { mapState } from 'vuex'

export default {
    data (){
        return {
            pdf: null,
            lin: 0,
            qtdLin: 0,
            page: 0,
            widthPage: 0,
            jump: 0,
            factorJump: 0,
            itensPage: 0,
            title: '',
            semData: false,
            semPagina: false,
            semRodape: false,
            //qtdPages : 0,
        }
    },
    created() {
        this.onCreatePDF();
    },
    computed: {
        ...mapState('Login',['infoEntidade'])
    },    
    methods: {
        async onCreatePDF (orientation  = 'P'){            
            this.qtdLin = 1
            this.page = 1
            this.jump = 3
            if (orientation  === 'P'){
                this.factorJump = 94;
                this.itensPage = 80;
                this.widthPage = 195;
                this.pdf = await new jsPDF('portrait','mm','a4');
                //filters: ['ASCIIHexEncode']
            } else {
                this.factorJump = 64;
                this.itensPage = 50;
                this.widthPage = 275;
                this.pdf = await new jsPDF('landscape','mm','a4');
            }
            this.pdf.setLanguage("pt-BR")
        },

        async onHeaderPDF () {
            this.pdf.setFont('helvetica','bold') 
            this.pdf.setFontSize(10)   
            this.pdf.rect(8,8,this.widthPage,19)
            this.pdf.text(this.infoEntidade.ent_nome,35,15)
            this.pdf.setFontSize(8)   
            this.pdf.text(this.infoEntidade.ent_cpf !== null ? 'CNPJ: '+this.infoEntidade.ent_cpf : '',35,20)
            this.pdf.setFont('helvetica','normal') 
            this.pdf.setFontSize(7)   
            this.pdf.text(this.infoEntidade.ent_email !== null ? String(this.infoEntidade.ent_email).toLowerCase() : '',35,25)

            if (!this.semData){
                this.pdf.setFont('helvetica','normal') 
                this.pdf.setFontSize(7)
                this.pdf.text(moment().format('DD/MM/YYYY'),(this.widthPage+5),7*this.jump,{align: 'right'})
            }
                        
            try {
                //this.pdf.addImage(this.infoEntidade.ent_logo_url, 'PNG', 10, 9, 16, 16/*, alias, compression, rotation*/)                
            } catch (error) {
                console.log(error);
            }
            if (this.title !== ''){
                this.pdf.line(8,35,(this.widthPage+8),35);
                this.pdf.setFontSize(10);
                this.pdf.text(String(this.title),((this.widthPage+5)/2),32,{align: 'center'});
                this.lin = 39;
            } else {
                this.lin = 32;
            }       
        },

        async onFooterPDF (data) {
            if (!this.semRodape){
                this.pdf.line(8,(this.factorJump*this.jump),(this.widthPage+8),(this.factorJump*this.jump))
                this.pdf.setFont('helvetica');
                this.pdf.setFont('helvetica','normal')
                this.pdf.setFontSize(7)               
                this.pdf.text('MERCATO ASSESSORIA - (66)3427-0889',8,((this.factorJump + 1)*this.jump))
                this.pdf.text(`PÁGINA Nº ${String(this.page)}`,(this.widthPage+5),((this.factorJump + 1)*this.jump),{align: 'right'})
            }
        },

        onAddLine (inc = 0) {
            this.lin += inc;
            this.onSaltarPagina();
        },

        onAddRegLine () {
            this.qtdLin += 1;
            this.onSaltarPagina();
        },

        onSaltarPagina (){
            if (this.qtdLin > this.itensPage || this.lin >= (this.factorJump*this.jump)){
                this.onFooterPDF()
                this.page++   
                this.pdf.addPage()
                this.onHeaderPDF();                 
                if (this.title !== ''){
                    this.lin = 39;
                } else {
                    this.lin = 32;
                }  
                this.qtdLin = 1 
            }
        },

        onSaltarPagina2 (){
            this.onFooterPDF()
            this.page++   
            this.pdf.addPage()
            this.onHeaderPDF();  
            if (this.title !== ''){
                this.lin = 39;
            } else {
                this.lin = 32;
            }  
            this.qtdLin = 1 
        },        

        onInsertNumberPagesPDF(){
            if (!this.semPagina){
                const pages = this.pdf.internal.getNumberOfPages();
                this.pdf.setFont('helvetica','normal') 
                this.pdf.setFontSize(7)
                for (let j = 1; j < pages + 1 ; j++) {
                    this.pdf.setPage(j);
                    this.pdf.text(`PÁGINA ${j} DE ${pages}`,(this.widthPage+5),8*this.jump,{align: 'right'})
                }
            }
        },

        onAlternarCor (value) {

            if(value % 2 === 0){
                this.pdf.setFillColor(232,232,232);
            }else{
                this.pdf.setFillColor(255,255,255);
            }

        }
    }    
}